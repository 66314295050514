<template lang="pug">
div(
  data-test="cl-list"
).mt-4.leaders-list
  v-data-table(
    :headers="headers"
    :items="list"
    item-key="ID"
    :server-items-length="list.length"
    :loading="loading"
    hide-default-header
    hide-default-footer
    class="table"
  )
    template(v-slot:top)
      div.d-flex.pa-2.align-center
        filters(@filterChanged="$emit('reload-list')")
        v-spacer
        v-btn(
          color="primary"
          small
          @click="openCourseLeaderModal"
          data-test="cl-create-btn"
        ) Create
      v-divider
      courseLeadersModalProvider(ref="courseLeaderModal" @reload-list="$emit('reload-list')")
        template(v-slot:default="props")
          courseLeadersModal(
            v-model="props.showing"
            :processing="props.processing"
            :model="model"
            @input="hideCreateModal"
            @submit="props.submit"
          )
    template(v-slot:header="{props, on}")
      tr
        th(v-for="(header, index) in props.headers" :key="index" :align="header.align").px-4 {{ header.text}}
    template(v-slot:item="{item}")
      list-item(
        :item="item"
        :key="'i' + item.ID"
        @on-edit="goToEdit"
        @reload-list="$emit('reload-list')"
      )
</template>

<script>
import {COURSE_LEADERS_CONST} from "../core/courseLeadersConst";
import {CourseLeader} from "../core/models/courseLeaderModel";

export default {
  props: {
    list: {
      type: Array,
      required: true
    },
    loading: Boolean
  },

  inject: ['svc'],

  data: () => ({
    headers: [
      { text: 'ID', value: COURSE_LEADERS_CONST.ID, sortable: true, align: 'left' },
      { text: 'Name', value: COURSE_LEADERS_CONST.NAME, sortable: false, align: 'left' },
      { text: 'Archived', value: COURSE_LEADERS_CONST.IS_ARCHIVED, sortable: false, align: 'left' },
      { text: 'Actions', sortable: false, align: 'left' },
    ],
    model: new CourseLeader()
  }),

  methods: {
    openCourseLeaderModal() {
      this.model = new CourseLeader();
      this.$refs.courseLeaderModal.toggle()
    },

    hideCreateModal(isShowing) {
      if(isShowing) return;
      this.model = new CourseLeader();
      this.$refs.courseLeaderModal.toggle()
    },

    goToEdit(item) {
      this.model = item;
      this.$refs.courseLeaderModal.toggle();
    }
  },

  components: {
    courseLeadersModal: () => import('./courseLeadersModal.vue'),
    courseLeadersModalProvider: () => import('./courseLeadersModalProvider.vue'),
    listItem: () => import('./courseLeadersListItem.vue'),
    filters: () => import('./filters/courseLeadersFilters.vue')
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/tableMain.scss";

.leaders-list::v-deep {
  .table th {
    border-right: 1px solid $border-color;
    &:last-child {
      border-right: unset;
    }
  }
  .table tr td {
    border-right: 1px solid $border-color;
    border-bottom :1px solid $border-color;
  }
}
</style>
